import React from 'react'
import Layout from '../../components/layout'
import LightBox from '../../components/lightbox/lightbox'

const Prozessmanagement = () => (
    <Layout>
        <section id="prozessmanagement" className="section-wrapper">
            <header className="section-header">
                <h3>Prozessmanagement</h3>
            </header>
            <div className="post">
                <h3>AXON iVY</h3>
                <p>
                    Um in dynamischen Märkten erfolgreich agieren zu können, müssen Unternehmen Ihre Prozesse laufend an neue Anforderungen anpassen.
                    Damit die Fachabteilungen diesen Veränderungen begegnen können, ist die IT gefordert, die Prozesse durch flexible IT-Lösungen zu unterstützen.
                    Gleichzeitig muss der stabile Betrieb stets gewährleistet sein und Investitionen müssen geschützt werden.
                </p>
                <p>
                    AXON iVY legt ein Netz von Prozessen über die bestehende funktionale IT-Infrastruktur und bedient Anwender und Systeme mit Daten und
                    Funktionalitäten. AXON iVY stellt das integrative Element dar und wird zum einzigen Berührungspunkt des Benutzers mit der IT-Infrastruktur.
                    Prozessmanagement schafft die Voraussetzungen für eine zeitgemässe, prozess- und serviceorientierte IT-Architektur und ermöglicht eine problemlose
                    und kontrollierte Integration der bestehenden Systeme, auch über Unternehmensgrenzen hinweg. Durch eine lose Koppelung über API-Schnittstellen
                    bleiben die bestehenden, funktionalen Anwendungen unberührt und releasefähig. Somit ist der Investitionsschutz gewährleistet. AXON.ivy ermöglicht
                    eigene Workflows zentral zu definieren und für alle Benutzer verfügbar zu machen. Aus diesen Prozessen können Aufgaben automatisch für in den Prozess
                    involvierte Mitarbeitende generiert werden. Prozesse können mit Einbindung von Drittapplikationen grafisch abgebildet, modelliert, simuliert und in
                    einem eigenständigen Web-Portal ausgeführt werden.
                </p>
                <br />
                <LightBox images={overviewImages} thumbnailHeight={90} />
            </div>
            <div className="post">
                <h3>Workflow Management</h3>
                <p>AXON iVY bindet Mitarbeitende optimal in die Abläufe von Geschäftsprozessen ein durch.</p>
                <ul>
                    <li>Applikations- und abteilungsübergreifendes Workflow Management</li>
                    <li>Integration von Daten und Funktionen verschiedener Systeme in einer Benutzeroberfläche</li>
                    <li>Paradigmenwechsel: „Der Anwender bedient nicht Systeme, sondern das Workflow Management System bedient den Anwender mit den benötigten Funktionen und Daten.“</li>
                    <li>Standortunabhängiges Arbeiten</li>
                    <li>Aufgabenzuteilung durch Business Workflow</li>
                    <li>Interne Verwaltung von Benutzern und Rollen oder Einbindung von Verzeichnisdiensten</li>
                    <li>Dramatische Reduktion der Liegezeiten dank „Push-Prinzip“ (der Mitarbeiter wird aufgefordert etwas zu tun) und Exception Handling</li>
                    <li>Steigerung der Prozessqualität dank durchgängiger Prozessüberwachung</li>
                </ul>
            </div>
            <div className="post">
                <h3>Prozessmodellierung</h3>
                <p>
                    AXON iVY deckt alle Bereiche der Prozessmodellierung in einem Werkzeug ab. Dazu gehören organisatorische und analytische Datenmodellierung,
                    Design des Datenflusses und Parametrierung von Benutzer- bzw. Systeminteraktion. Aufwändige Importe und Exporte zwischen verschiedenen Werkzeugen entfallen.
                    Um den unterschiedlichen Ansprüchen von Benutzergruppen gerecht zu werden, verfügt AXON iVY über:
                </p>
                <ul>
                    <li>Unterschiedliche Sichten auf dasselbe Prozessmodell je nach Benutzergruppe</li>
                    <li>Prozessmodellierung mit Notationsstandard BPMN der Object Management Group (OMG)</li>
                    <li>Publikation von Prozesshandbüchern als Word-Dokument oder im Web</li>
                </ul>
            </div>
            <div className="post">
                <h3>Prozessausführung</h3>
                <p>
                    AXON iVY beherrscht die Disziplin der Business Process Automation in Perfektion. Mit wenigen Mausklicks werden Ablaufdiagramme aus der Designumgebung an
                    die Laufzeitumgebung übergeben. Dort werden sie interpretiert und ausgeführt. So gelangt man auf graphischem Weg von der Prozessdarstellung zur
                    Prozessausführung ohne eine Zeile zu programmieren. Ebenso einfach können Prozessapplikationen an neue Anforderungen angepasst werden.
                </p>
            </div>

        </section>
    </Layout>
)

const overviewImages = [
    {
        index: 0,
        src: 'https://lepa.ch/lepa/wp-content/uploads/2012/03/Prozessmanagement-1-Konzept.jpg',
        title: 'Konzept',
        description: ''
    },
    {
        index: 1,
        src: 'https://lepa.ch/lepa/wp-content/uploads/2012/03/Prozessmanagement-2-Pflichtenheft2.jpg',
        title: 'Pflichtenheft',
        description: ''
    },
    {
        index: 2,
        src: 'https://lepa.ch/lepa/wp-content/uploads/2012/03/Prozessmanagement-3-Sollprozesse.jpg',
        title: 'Soll-Prozesse',
        description: ''
    },
    {
        index: 3,
        src: 'https://lepa.ch/lepa/wp-content/uploads/2012/03/Prozessmanagement-4-Sollprozesse-Varianten.jpg',
        title: 'Soll-Prozesse Varianten',
        description: ''
    },
    {
        index: 4,
        src: 'https://lepa.ch/lepa/wp-content/uploads/2012/03/Prozessmanagement-5-Mockups.jpg',
        title: 'Mockups',
        description: ''
    },
    {
        index: 5,
        src: 'https://lepa.ch/lepa/wp-content/uploads/2012/03/Prozessmanagement-6-GUI.jpg',
        title: 'GUI',
        description: ''
    }

];

export default Prozessmanagement
